export const SIDEBAR_VARIANTS = {
  PERSISTENT: "persistent",
  TEMPORARY: "temporary",
  PERMANENT: "permanent",
};

export const SIDEBAR_STYLES = {
  FULL_HEIGHT: "full-height",
  CLIPPED_UNDER_HEADER: "clipped-under-header",
};

export const SIDEBAR_VIEWS = {
  MINI: "mini",
  FULL: "full",
};

export const SIDEBAR_SCROLL_TYPES = {
  DEFAULT: "default",
  FIXED: "fixed",
};

export const SIDEBAR_ANCHOR_POSITIONS = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
};

export const LAYOUT_ACTIONS = {
  SET_SIDEBAR_OPTIONS: "set-sidebar-options",
  SET_HEADER_OPTIONS: "set-header-options",
  SET_FOOTER_OPTIONS: "set-footer-options",
  SET_OPTIONS: "set-options",
  SET_ROOT_OPTIONS: "set-root-options",
  SET_CONTENT_OPTIONS: "set-content-options",
};

export const DENSITIES = {
  STANDARD: "standard",
  COMPACT: "compact",
  COMFORTABLE: "comfortable",
};

export const LAYOUT_CONTAINER_STYLES = {
  FLUID: "fluid",
  BOXED: "boxed",
};

export const POSITION_TYPES = {
  STICKY: "sticky",
  DEFAULT: "default",
};

export const CONTENT_LAYOUT_ACTIONS = {
  UPDATE_HEADER_OPTIONS: "update_header_options",
  UPDATE_FOOTER_OPTIONS: "update_footer_options",
  UPDATE_CONTENT_OPTIONS: "update_content_options",
  UPDATE_SIDEBAR_OPTIONS: "update_content_options",
  UPDATE_LAYOUT_OPTIONS: "update_layout_options",
  UPDATE_EXTRA_OPTIONS: "update_extra_options",
  RESET: "reset_layout_options",
};
