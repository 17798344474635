import { findGetParameterCurrentPage } from "cofa/utils/urls";

export function constructReturnUrl(baseUrl, returnUrl) {
  let searchParams = new URLSearchParams("");
  searchParams.set(
    RETURN_TO_LABEL,
    returnUrl ?? window.location.pathname + window.location.search
  );
  return baseUrl + "?" + searchParams.toString();
}

export function getReturnUrl() {
  return findGetParameterCurrentPage(RETURN_TO_LABEL);
}

const RETURN_TO_LABEL = "returnTo";
