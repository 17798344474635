import { useDispatch, useSelector } from "react-redux";
import {
  authStatusIsLoadingSelector,
  authStatusSelector,
  authUserSelector,
  getAuthUser,
} from "app/pages/auth/hooks/authSlice";
import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ComputerIcon from "@mui/icons-material/Computer";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import PortraitIcon from "@mui/icons-material/Portrait";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HowToRegIcon from "@mui/icons-material/HowToReg";

export function useAuth() {
  const user = useSelector(authUserSelector);
  const status = useSelector(authStatusSelector);
  const authIsLoading = useSelector(authStatusIsLoadingSelector);
  const dispatch = useDispatch();

  const retrieveAuthUser = React.useCallback(() => {
    dispatch(getAuthUser({}));
  }, [dispatch]);

  React.useEffect(() => {
    if (status === "idle") {
      retrieveAuthUser();
    }
  }, [retrieveAuthUser, status]);

  const authUser = React.useMemo(() => {
    return {
      data: user,
      isLoading: authIsLoading,
    };
  }, [authIsLoading, status, user]);

  const role = React.useMemo(() => {
    return authUser?.data?.mainRole;
  }, [authUser]);

  const roleRef = React.useMemo(() => {
    return authUser?.data?.mainRole?.ref;
  }, [authUser]);

  const isSupport = React.useMemo(
    () => roleRef === "support" || roleRef === "super_admin",
    [roleRef]
  );

  const isAgent = React.useMemo(() => roleRef === "agent", [roleRef]);

  const isController = React.useMemo(() => roleRef === "controller", [roleRef]);

  const isCashier = React.useMemo(() => roleRef === "cashier", [roleRef]);

  const isDAF = React.useMemo(() => roleRef === "daf", [roleRef]);

  const isNotConsultative = React.useMemo(
    () => (roleRef ? role?.isNotConsultative : false),
    [roleRef]
  );

  return {
    isController: isController,
    isNotConsultative: isNotConsultative,
    isConsultative: !isNotConsultative,
    isLoggedIn: !!user,
    authUser: {
      data: user,
      isLoading: authIsLoading,
    },
    user: user,
    role: roleRef,
    roleName: _userRoleNameFromRef(roleRef),
    isAgent: isAgent,
    isDAF: isDAF,
    isSupport: isSupport,
    isCashier: isCashier,
  };
}

export const UserRoleName = Object.freeze({
  AGENT: Symbol("agent"),
  CONTROLLER: Symbol("controller"),
  CASHIER: Symbol("cashier"),

  HEAD_DIVISION: Symbol("head_division"),
  HEAD_SECTION: Symbol("head_section"),
  CONSULTATIVE: Symbol("consultative"),
  MAYOR: Symbol("mayor"),
  RH: Symbol("rh"),
  SG: Symbol("sg"),
  DAF: Symbol("daf"),

  SUPPORT: Symbol("support"),
  UNKNOWN: Symbol("none"),
});

const _userRoleNameFromRef = (roleRef) => {
  switch (roleRef) {
    case "agent":
      return UserRoleName.AGENT;
    case "controller":
      return UserRoleName.CONTROLLER;
    case "cashier":
      return UserRoleName.CASHIER;

    case "head_division":
      return UserRoleName.HEAD_DIVISION;

    case "head_section":
      return UserRoleName.HEAD_SECTION;

    case "consultative":
      return UserRoleName.CONSULTATIVE;

    case "mayor":
      return UserRoleName.MAYOR;

    case "rh":
      return UserRoleName.RH;

    case "sg":
      return UserRoleName.SG;

    case "daf":
      return UserRoleName.DAF;

    case "support":
      return UserRoleName.SUPPORT;

    default:
      return UserRoleName.UNKNOWN;
  }
};
export const rolesAndIcons = [
  {
    role: UserRoleName.AGENT,
    icon: <PeopleIcon fontSize={"large"} />,
    color: "blue",
    label: "Collecteurs",
  },
  {
    role: UserRoleName.CONTROLLER,
    icon: <HowToRegIcon fontSize={"large"} />,
    color: "orange",
    label: "Contrôleurs",
  },
  {
    role: UserRoleName.CASHIER,
    icon: <AssignmentIndIcon fontSize={"large"} />,
    color: "grey",
    label: "Caissiers",
  },
  {
    role: UserRoleName.HEAD_DIVISION,
    icon: <PortraitIcon fontSize={"large"} />,
    color: "green",
    label: "Chef Division",
  },
  {
    role: UserRoleName.HEAD_SECTION,
    icon: <PortraitIcon fontSize={"large"} />,
    color: "green",
    label: "Chef Section",
  },
  {
    role: UserRoleName.CONSULTATIVE,
    icon: <ContactEmergencyIcon fontSize={"large"} />,
    color: "green",
    label: "Compte consultative",
  },
  {
    role: UserRoleName.MAYOR,
    icon: <LocalPoliceIcon fontSize={"large"} />,
    color: "red",
    label: "Maire",
  },
  {
    role: UserRoleName.RH,
    icon: <ManageAccountsIcon fontSize={"large"} />,
    color: "green",
    label: "RH",
  },
  {
    role: UserRoleName.SG,
    icon: <ComputerIcon fontSize={"large"} />,
    color: "green",
    label: "SG",
  },
  {
    role: UserRoleName.DAF,
    icon: <AccountBalanceIcon fontSize={"large"} />,
    color: "green",
    label: "DAF",
  },

  {
    role: UserRoleName.SUPPORT,
    icon: <SupportAgentIcon fontSize={"large"} />,
    color: "green",
    label: "Support",
  },
];

export const userRolesSupport = [
  {
    slug: "controller",
    label: "Contrôleur",
  },
  {
    slug: "cashier",
    label: "Caissier",
  },
  {
    slug: "agent",
    label: "Agent",
  },
];

export const userRolesSuperAdmin = [
  {
    slug: "support",
    label: "Support",
  },
  ...userRolesSupport,
];
