import Div from "@jumbo/shared/Div";

const SpaceBetweenDiv = ({
  children,
  mb = 0,
  alignItems = "center",
  alignContent,
  justifyContent = "space-between",
  sx = {},
}) => {
  return (
    <Div
      sx={{
        display: "flex",
        justifyContent: justifyContent,
        alignContent: alignContent,
        alignItems: alignItems,
        mb: mb,
        ...sx,
      }}
    >
      {children}
    </Div>
  );
};

export default SpaceBetweenDiv;
