import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import React from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

const assignmentMenu = [
  {
    uri: "/assignation",
    label: "sidebar.menuItem.assignMent",
    type: "nav-item",
    icon: <AssignmentIndIcon sx={{ fontSize: 20 }} />,
  },
];

const usersListMenu = [
  {
    uri: "/users",
    label: "sidebar.menuItem.list",
    type: "nav-item",
    icon: <PeopleAltIcon sx={{ fontSize: 20 }} />,
  },
];

export const usersMenus = [
  {
    label: "sidebar.menu.users",
    type: "section",
    children: [...usersListMenu, ...assignmentMenu],
  },
];
export const onlyUsersMenu = [
  {
    label: "sidebar.menu.users",
    type: "section",
    children: [...usersListMenu],
  },
];
