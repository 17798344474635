import { UserRoleName } from "app/routes/hooks/useAuth";
import {
  AgentCollectorRole,
  getPlaceNameFromUserRole,
  placeTypeNameOf,
} from "app/pages/users/models/AgentRole";
import React from "react";

class UserRole {
  constructor(userRole) {
    Object.assign(this, userRole);
  }

  static fromJson(userRole) {
    const roleRef = userRole["ref"];
    const zoneCode = userRole["zone_code"];
    const zoneType = userRole["zone_type"];
    const marketCode = userRole["market_code"];

    const isNotConsultative = roleRef
      ? roleRef === UserRoleName.AGENT.description ||
        roleRef === UserRoleName.CASHIER.description ||
        roleRef === UserRoleName.CONTROLLER.description ||
        roleRef === UserRoleName.DAF.description ||
        roleRef === UserRoleName.SUPPORT.description
      : false;

    const agentRole =
      roleRef === UserRoleName.AGENT.description
        ? marketCode
          ? AgentCollectorRole.MARKET
          : zoneType?.toLowerCase() === "parking"
          ? AgentCollectorRole.PARKING
          : AgentCollectorRole.OBSTRUCTION
        : AgentCollectorRole.NOT_COLLECTOR;

    const placeName = getPlaceNameFromUserRole(agentRole, userRole);

    return new UserRole({
      ...userRole,
      isNotConsultative: isNotConsultative,
      isConsultative: !isNotConsultative,
      zoneType: zoneType,
      agentRole: agentRole,
      isObstructionOrParking:
        agentRole === AgentCollectorRole.OBSTRUCTION ||
        agentRole === AgentCollectorRole.PARKING,
      canMakePenalty: userRole["can_make_penalty"],
      isObstruction: agentRole === AgentCollectorRole.OBSTRUCTION,
      isParking: agentRole === AgentCollectorRole.PARKING,
      placeTypeName: placeTypeNameOf(agentRole),
      placeName: placeName,
      ref: roleRef,
      name: userRole["name"],
      description: userRole["description"],
      zoneCode: zoneCode,
      marketCode: marketCode,
      initialBalance: userRole["initial_balance"],
      zoneName: userRole["zone_name"],
      marketName: userRole["market_name"],
      startAt: userRole["start_at"],
      endAt: userRole["end_at"],
      disabledAt: userRole["disabled_at"],
    });
  }
}

export default UserRole;
