export function isArray(o) {
  // Seem the best approach, according to this article : http://perfectionkills.com/instanceof-considered-harmful-or-how-to-write-a-robust-isarray/
  return Object.prototype.toString.call(o) === "[object Array]";
}

export function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}
