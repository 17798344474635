import DashboardIcon from "@mui/icons-material/Dashboard";
import React from "react";

export const homeMenus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/",
        label: "sidebar.menuItem.dashboard",
        type: "nav-item",
        icon: <DashboardIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];
