import React from "react";
const ObstructionDailyReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/ObstructionDailyReceiptsList")
);
const ObstructionMonthlyReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/ObstructionMonthlyReceiptsList")
);
const MarketDailyReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/MarketDailyReceiptsList")
);
const MarketSimplePenaltyReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/MarketSimplePenaltyReceiptsList")
);
const MarketMonthlyReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/MarketMonthlyReceiptsList")
);
const ParkingReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/ParkingReceiptsList")
);
const PenaltiesReceiptsList = React.lazy(() =>
  import("app/pages/receipts/pages/ReceiptsListPages/PenaltiesReceiptsList")
);
const ReceiptsAnnulationPage = React.lazy(() =>
  import('app/pages/receipts/features/cancellations/ReceiptsAnnulationPage'),
);
const ReceiptsAnnulationsListPage = React.lazy(() =>
  import('app/pages/receipts/features/cancellations/ReceiptsAnnulationsListPage'),
);
const ReceiptsAnnulationFormPage = React.lazy(() =>
  import('app/pages/receipts/features/cancellations/ReceiptsAnnulationFormPage'),
);
export const receiptsPaths = [
  {
    path: "/receipts/obstruction/monthly",
    element: <ObstructionMonthlyReceiptsList />,
  },
  {
    path: "/receipts/obstruction/daily",
    element: <ObstructionDailyReceiptsList />,
  },
  {
    path: "/receipts/parking",
    element: <ParkingReceiptsList />,
  },
  {
    path: "/receipts/penalty",
    element: <PenaltiesReceiptsList />,
  },
  {
    path: "/receipts/market/monthly",
    element: <MarketMonthlyReceiptsList />,
  },
  {
    path: "/receipts/market/daily",
    element: <MarketDailyReceiptsList />,
  },
  {
    path: "/receipts/market/penalty",
    element: <MarketSimplePenaltyReceiptsList />,
  },
  {
    path: '/receipts/cancellations',
    element: <ReceiptsAnnulationPage />,
  },
  {
    path: '/receipts/cancellations/list',
    element: <ReceiptsAnnulationsListPage />,
  },
  {
    path: '/receipts/cancellations/new',
    element: <ReceiptsAnnulationFormPage />,
  },
];
