import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import React from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";

const cashierAgentsManagementItemMenu = {
  uri: "/agents/receipts",
  label: "sidebar.menuItem.agents",
  type: "nav-item",
  icon: <ReceiptIcon sx={{ fontSize: 20 }} />
};

const commonsReceiptsMenuItems = [
  {
    uri: "/agents/reports",
    label: "sidebar.menuItem.receiptsList",
    type: "nav-item",
    icon: <ListAltIcon sx={{ fontSize: 20 }} />
  },
  {
    uri: "/agents/deposits",
    label: "sidebar.menuItem.history",
    type: "nav-item",
    icon: <LibraryBooksIcon sx={{ fontSize: 20 }} />
  }
];

const commonsReceiptsMenu = [
  {
    label: "sidebar.menuItem.agentsReceipts",
    type: "collapsible",
    icon: <ReceiptIcon sx={{ fontSize: 20 }} />,
    children: [
      ...commonsReceiptsMenuItems
    ]
  }
];

const cashierReceiptsMenu = [
  {
    label: "sidebar.menuItem.agentsReceipts",
    type: "collapsible",
    icon: <ReceiptIcon sx={{ fontSize: 20 }} />,
    children: [
      cashierAgentsManagementItemMenu,
      ...commonsReceiptsMenuItems
    ]
  }
];

export const agentsReceiptsAndManagement = [
  {
    label: "sidebar.menu.manage",
    type: "section",
    children: [
      {
        uri: "/agents/management",
        label: "sidebar.menuItem.agentsManagement",
        type: "nav-item",
        icon: <PeopleAltIcon sx={{ fontSize: 20 }} />
      },
      ...cashierReceiptsMenu
    ]
  }
];
export const agentReceipts = [
  {
    label: "sidebar.menu.manage",
    type: "section",
    children: [...commonsReceiptsMenu]
  }
];
