import { createSlice } from "@reduxjs/toolkit";

const agentsSlice = createSlice({
  name: "agents",
  initialState: {
    agent: null,
    receipts: null,
    searching: false,
  },
  reducers: {
    setAgentSelected(state, action) {
      state.agent = action.payload;
    },
  },
});

export const { setAgentSelected } = agentsSlice.actions;

export const selectedAgentSelector = (state) => state.agents.agent;

export const selectedReceiptsSelector = (state) => state.agents.receipts;
export const agentIsSearchSelector = (state) => state.agents.searching;

export const setSelectedAgent = (agent) => {
  return (dispatch) => {
    dispatch(setAgentSelected(agent));
  };
};

export const setSelectedReceipts = ({ category, receipts: list }) => {
  return (dispatch) => {
    dispatch(setAgentSelected({ category, list }));
  };
};

export default agentsSlice.reducer;
