class TaxPayerZone {
  type;
  typeName;

  ref;

  name;
  description;

  invoiceTypeName;
  invoiceTypeAmount;

  sign;
  nif;

  activity;
  activityStartedAt;
  activityEndedAt;
  lastPaymentPeriod;

  longitude;
  latitude;

  agentId;
  agentName;

  taxPayerZoneRef;

  taxPayer;

  constructor(zone) {
    Object.assign(this, zone);
  }

  static fromJson(zone, taxPayer) {
    return new TaxPayerZone({
      ...taxPayer,
      type: "zone",
      typeName: "Encombrement",
      ref: zone["ref"],
      name: zone["name"],
      description: zone["description"],
      invoiceTypeName: zone["invoice_type_name"],
      invoiceTypeAmount: zone["invoice_type_amount"],
      sign: zone["sign"],
      nif: zone["nif"],
      activity: zone["activity"],
      activityStartedAt: zone["activity_started_at"],
      activityEndedAt: zone["activity_ended_at"],
      activityEnded: !!zone["activity_ended_at"],
      lastPaymentPeriod: zone["last_payment_period"],
      longitude: zone["longitude"],
      latitude: zone["latitude"],
      agentId: zone["agent_id"],
      agentName: zone["agent_name"],
      taxPayerZoneRef: zone["tax_payer_zone_ref"],
      taxPayer: taxPayer,
    });
  }

  getSearchSlug(additionalValues = []) {
    return [
      ...additionalValues,
      this.type,
      this.typeName,
      this.ref,
      this.name,
      this.description,
      this.invoiceTypeName,
      this.invoiceTypeAmount,
      this.sign,
      this.nif,
      this.activity,
      this.activityStartedAt,
      this.activityEndedAt ?? "",
      this.lastPaymentPeriod,
      this.longitude,
      this.latitude,
      this.agentId,
      this.agentName,
      this.taxPayerZoneRef,
      this.taxPayer.ref,
      this.taxPayer.nif,
      this.taxPayer.phone,
      this.taxPayer.normalizedPhone,
      this.taxPayer.firstName,
      this.taxPayer.lastName,
      this.taxPayer.name,
      this.taxPayer.email,
      this.taxPayer.address,
      this.taxPayer.agentId,
      this.taxPayer.agentName,
    ].join("");
  }

  populateWithData() {
    //Generate search slug that easier search.
    this.searchSlug = this.getSearchSlug();

    //Use this method in fluent mode.
    return this;
  }
}

export default TaxPayerZone;
