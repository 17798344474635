import TaxPayerZone from "app/pages/taxPayers/models/TaxPayerZone";
import TaxPayerMarket from "app/pages/taxPayers/models/TaxPayerMarket";

class TaxPayer {
  ref;
  nif;
  phone;
  normalizedPhone;
  firstName;
  lastName;
  name;
  email;
  address;
  agentId;
  agentName;
  zones;
  markets;

  constructor(taxPayer) {
    Object.assign(this, taxPayer);
  }

  static fromJson(taxPayer) {
    const phone = taxPayer["phone"] ?? "";
    const normalizedPhone = phone
      ? phone
      : phone.startsWith("+")
      ? phone
      : phone.startsWith("228")
      ? "+" + phone
      : "+228" + phone;

    const taxPayerModel = new TaxPayer({
      nifOrRef: taxPayer["nif"] ?? taxPayer["ref"],
      ref: taxPayer["ref"],
      nif: taxPayer["nif"],
      phone: phone,
      normalizedPhone: normalizedPhone,
      firstName: taxPayer["first_name"],
      lastName: taxPayer["last_name"],
      name:
        taxPayer["first_name"] &&
        taxPayer["last_name"] &&
        taxPayer["last_name"]?.toUpperCase() + " " + taxPayer["first_name"],
      email: taxPayer["email"],
      address: taxPayer["address"],
      agentId: taxPayer["agent_id"],
      agentName: taxPayer["agent_name"],
      simplePenalties: taxPayer["simple_penalties"],
    });

    const zones = (
      taxPayer["zone"] ? [taxPayer["zone"]] : taxPayer["zones"] ?? []
    ).map((zone) => TaxPayerZone.fromJson(zone, taxPayerModel));

    const markets = (
      taxPayer["market"] ? [taxPayer["market"]] : taxPayer["markets"] ?? []
    ).map((market) => TaxPayerMarket.fromJson(market, taxPayerModel));

    return {
      ...taxPayerModel,
      ...(markets.length === 0
        ? {
            type: "zone",
            typeName: "Encombrement",
          }
        : {
            type: "market",
            typeName: "Marché",
          }),
      zones: zones,
      markets: markets,
    };
  }

  static populateWithData(taxPayer, additionalValues = []) {
    //Generate search slug that easier search.
    taxPayer.searchSlug = [
      ...additionalValues,
      taxPayer.ref,
      taxPayer.nif,
      taxPayer.phone,
      taxPayer.normalizedPhone,
      taxPayer.firstName,
      taxPayer.lastName,
      taxPayer.name,
      taxPayer.email,
      taxPayer.address,
      taxPayer.agentId,
      taxPayer.agentName,
    ].join("");

    //Use this method in fluent mode.
    return taxPayer;
  }
}

export default TaxPayer;
