import React from "react";
import Auth from "app/routes/middleware/Auth";
import NotAuth from "app/routes/middleware/NotAuth";
import { supportPaths } from "app/routes/paths/support";
import { cashierPaths } from "app/routes/paths/cashier";
import { receiptsPaths } from "app/routes/paths/receipts";
import { authRoutes } from "app/routes/paths/auth";
import { agentPaths } from "app/routes/paths/agent";
import { noAuthRoutes } from "app/routes/paths/noAuth";
import { settingsRoutes } from "app/routes/paths/settings";
import { homeRoutes } from "app/routes/paths/home";
import { appData } from "app/routes/paths/appData";

const routes = [
  ...noAuthRoutes,
  {
    middleware: [
      {
        element: NotAuth,
        fallbackPath: "/",
      },
    ],
    routes: [
      ...authRoutes
    ],
  },
  {
    middleware: [
      {
        element: Auth,
        fallbackPath: "/login",
      },
    ],
    routes: [
      ...homeRoutes,
      ...appData,
      ...settingsRoutes,
      ...supportPaths,
      ...cashierPaths,
      ...receiptsPaths,
      ...agentPaths,
    ],
  },
];

export default routes;
