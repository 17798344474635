import React from "react";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";

// const Logo = ({mini, mode, sx}) => {
//   return (<Div sx={{display: "inline-flex", ...sx}}>
//     <Link href={'/'}>
//       {!mini ? <img width={35} src={mode === "light" ? `${LOGO_IMAGES}/otr-colored.png` : `${LOGO_IMAGES}/otr-colored.png`}
//                     alt="OTR Logo"/> :
//         <img width={35} src={mode === "light" ? `${LOGO_IMAGES}/otr-colored.png` : `${LOGO_IMAGES}/otr-colored.png`}
//              alt="OTR Logo"/>}
//     </Link>
//   </Div>);
// };
const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link href={"/"}>
        {!mini ? (
          <img width={35} src={`/assets/images/mtax.jpg`} alt="MTAX" />
        ) : (
          <img width={35} src={`/assets/images/mtax.jpg`} alt="OTR Logo" />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
