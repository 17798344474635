import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    refreshList: false,
    selected: [],
  },
  reducers: {
    setListRefresh(state, action) {
      state.refreshList = action.payload;
    },
    setSelectedUsers(state, action) {
      state.selected = action.payload;
    },
  },
});

export const { setListRefresh, setSelectedUsers } = usersSlice.actions;

export const refreshUsersListSelector = (state) => state.users.refreshList;

export const setUsersListRefresh = (refresh) => {
  return (dispatch) => {
    dispatch(setListRefresh(refresh));
  };
};

export const setSelectedUsersList = (users) => {
  return (dispatch) => {
    dispatch(setSelectedUsers(users));
  };
};

export default usersSlice.reducer;
