import React from "react";
const UsersAssignmentPage = React.lazy(() => import("app/pages/assignments"));
const UsersPage = React.lazy(() => import("app/pages/users"));
const UserDetailPage = React.lazy(() =>
  import('app/pages/users/pages/UserDetailPage')
);
export const supportPaths = [
  {
    path: "/users",
    element: <UsersPage />,
  },
  {
    path: '/user/:agent_id',
    element: <UserDetailPage />,
  },
  {
    path: "/assignation",
    element: <UsersAssignmentPage />,
  },
];
