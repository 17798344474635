import TaxPayer from "app/pages/taxPayers/models/TaxPayer";
import { withStatus, withTimesTamps } from "app/pages/receipts/models/commons";
import { formatCurrency } from "cofa/utils/functions/text";

class MarketDailyDepositTaxReceipt {
  ref;
  code;
  amount;
  paymentDate;
  marketRef;
  marketName;
  agentId;
  agentName;
  agentDepositDate;
  agentDepositRef;
  cashierId;
  cashierName;
  cashierDepositDate;
  canceled;
  canceledAt;
  canceledBy;
  cancelReason;
  canceledByName;
  longitude;
  latitude;
  status;
  taxPayer;
  paymentMode;
  searchSlug;

  constructor(receipt) {
    Object.assign(this, receipt);
  }

  static fromJson(receipt) {
    const taxPayer =
      receipt["tax_payer"] && TaxPayer.fromJson(receipt["tax_payer"]);

    return new MarketDailyDepositTaxReceipt({
      ref: receipt["ref"],

      code: receipt["code"],

      amount: receipt["amount"],
      paymentDate: receipt["payment_date"],

      marketName: receipt["market_name"],
      marketRef: receipt["market_ref"],
      market: receipt["market_ref"] + " | " + receipt["market_name"],

      agentId: receipt["agent_id"],
      agentName: receipt["agent_name"],
      agent: receipt["agent_id"] + " | " + receipt["agent_name"],
      agentDepositDate: receipt["agent_deposit_date"],
      agentDepositRef: receipt["agent_deposit_ref"],

      deposed: !!receipt["agent_deposit_date"],
      collected: !!receipt["agent_deposit_date"],

      cashierId: receipt["cashier_id"],
      cashierName: receipt["cashier_name"],
      cashierDepositDate: receipt["cashier_deposit_date"],

      canceled: !!receipt["canceled_at"],
      notCanceled: !receipt["canceled_at"],
      canceledAt: receipt["canceled_at"],
      canceledBy: receipt["canceled_by_id"],
      cancelReason: receipt["cancel_reason"],
      canceledByName: receipt["canceled_by_name"],
      canceledByAgent:
        receipt["canceled_by_id"] + " | " + receipt["canceled_by_name"],

      longitude: receipt["longitude"],
      latitude: receipt["latitude"],

      taxPayer: taxPayer,
      taxPayerName: taxPayer?.name,

      ...withTimesTamps(receipt),
      ...withStatus(receipt),
    });
  }

  getLabelFr(modes) {
    return (
      modes.find((mode) => mode.code === this.paymentMode)?.label ??
      this.paymentMode
    );
  }

  getSearchSlug(additionalValues = []) {
    return [
      ...additionalValues,
      this.ref,
      this.code,
      this.taxPayer?.ref,
      this.taxPayer?.address,
      this.taxPayer?.phone,
      this.taxPayer?.nif,
      this.taxPayer?.address,
      this.taxPayer?.firstName,
      this.taxPayer?.lastName,
      this.amount,
      formatCurrency(this.amount),
      this.marketRef,
      this.marketName,
      this.paymentMode,
      this.agentId,
      this.agentName,
      this.agentDepositRef,
      this.cashierId,
      this.cashierName,
      this.status,
      this.canceledBy,
      this.cancelReason,
      this.canceledByName,
    ].join("");
  }

  populateWithData({ paymentModes = [] }) {
    //Translate Payment mode to French.
    this.paymentModeFr = this.getLabelFr(paymentModes);

    //Generate search slug that easier search.
    this.searchSlug = this.getSearchSlug([this.paymentModeFr]);

    //Use this method in fluent mode.
    return this;
  }

  asCanceled() {
    this.amount = -1 * this.amount;
    this.canceled = true;

    return this;
  }

  removeCanceledState() {
    this.canceled = false;

    this.canceledAt = null;
    this.canceledBy = null;
    this.canceledByName = null;
    this.cancelReason = null;

    return this;
  }
}

export default MarketDailyDepositTaxReceipt;
