import React from "react";
import TrafficIcon from "@mui/icons-material/Traffic";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import StorefrontIcon from "@mui/icons-material/Storefront";
import TodayIcon from "@mui/icons-material/Today";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

export const cashierMenus = [
  {
    label: "sidebar.menu.values",
    type: "section",
    children: [
      {
        uri: "/receipts/parking",
        label: "sidebar.menuItem.parking",
        type: "nav-item",
        icon: <LocalParkingIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/receipts/penalty",
        label: "sidebar.menuItem.penalties",
        type: "nav-item",
        icon: <RequestQuoteIcon sx={{ fontSize: 20 }} />,
      },
      {
        label: "sidebar.menuItem.obstruction",
        type: "collapsible",
        icon: <TrafficIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/receipts/obstruction/daily",
            label: "sidebar.menuItem.daily",
            type: "nav-item",
            icon: <TodayIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/receipts/obstruction/monthly",
            label: "sidebar.menuItem.monthly",
            type: "nav-item",
            icon: <CalendarMonthIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      {
        uri: "/receipts/market",
        label: "sidebar.menuItem.market",
        type: "collapsible",
        icon: <StorefrontIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/receipts/market/daily",
            label: "sidebar.menuItem.daily",
            type: "nav-item",
          },
          {
            uri: "/receipts/market/penalty",
            label: "sidebar.menuItem.simplePenalty",
            type: "nav-item",
          },
          {
            uri: "/receipts/market/monthly",
            label: "sidebar.menuItem.monthly",
            type: "nav-item",
          },
        ],
      },
    ],
  },
];
