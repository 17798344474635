import React from "react";
import BusinessIcon from '@mui/icons-material/Business';
import MoneyOffCsred from '@mui/icons-material/MoneyOffCsred';

export const taxPayersMenu = [
  {
    label: "sidebar.menu.manage",
    type: "section",
    children: [
      {
        uri: "/tax-payers/all",
        label: "sidebar.menuItem.taxPayers",
        type: "nav-item",
        icon: <BusinessIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/tax-payers/unpaid",
        label: "sidebar.menuItem.unpaid",
        type: "nav-item",
        icon: <MoneyOffCsred sx={{ fontSize: 20 }} />,
      },
    ],
  },
];
