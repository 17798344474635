import ReceiptIcon from "@mui/icons-material/Receipt";
import MapIcon from "@mui/icons-material/Map";
//import CategoryIcon from "@mui/icons-material/Category";
import StorefrontIcon from "@mui/icons-material/Storefront";
import React from "react";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export const appDataMenu = [
  {
    label: "sidebar.menu.appData",
    type: "section",
    children: [
      /* {
         uri: "/app/activities",
         label: "sidebar.menuItem.activities",
         type: "nav-item",
         icon: <CategoryIcon sx={{ fontSize: 20 }} />,
       },*/
      {
        uri: "/app/parking-types",
        label: "sidebar.menuItem.parkingTypes",
        type: "nav-item",
        icon: <LocalParkingIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/penalty-types",
        label: "sidebar.menuItem.penaltyTypes",
        type: "nav-item",
        icon: <RequestQuoteIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/invoice-types",
        label: "sidebar.menuItem.invoiceTypes",
        type: "nav-item",
        icon: <ReceiptIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/markets",
        label: "sidebar.menuItem.markets",
        type: "nav-item",
        icon: <StorefrontIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/app/zones",
        label: "sidebar.menuItem.zones",
        type: "nav-item",
        icon: <MapIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];
