import baseAxios from "axios";

export const APP_URL = process.env.REACT_APP_API_PROD_URL;

const axios = baseAxios.create({
  baseURL: APP_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      localStorage.getItem("token_type") + " " + localStorage.getItem("token"),
  },
});

export default axios;
