import UserRole from "app/pages/users/models/UserRole";
import Tax from "app/pages/receipts/models/Tax";
import { UserRoleName } from "app/routes/hooks/useAuth";
import PaymentMode from "app/pages/receipts/models/PaymentMode";
import ParkingDepositReceipt from "app/pages/receipts/models/ParkingDepositReceipt";
import DailyObstructionDepositReceipt from "app/pages/receipts/models/DailyObstructionDepositReceipt";
import PenaltyDepositReceipt from "app/pages/receipts/models/PenaltyDepositReceipt";
import MonthlyObstructionDepositReceipt from "app/pages/receipts/models/MonthlyObstructionDepositReceipt";
import MarketMonthlyDepositReceipt from "app/pages/receipts/models/MarketMonthlyDepositReceipt";
import MarketDailyDepositTaxReceipt from "app/pages/receipts/models/MarketDailyDepositReceipt";
import MarketSimplePenaltyTaxReceipt from "app/pages/receipts/models/MarketSimplePenaltyTaxReceipt";

class User {
  parkingWaitingDepositsReceipts;
  penaltiesWaitingDepositsReceipts;
  obstructionDailyWaitingDepositsReceipts;
  obstructionMonthlyWaitingDepositsReceipts;
  marketDailyWaitingDepositsReceipts;
  marketSimplePenaltyTaxesDepositsReceipts;
  marketMonthlyWaitingDepositsReceipts;

  constructor(user) {
    Object.assign(this, user);
  }

  static fromJson(json) {
    try {
      const roles = (json["roles"] ?? []).map((role) =>
        UserRole.fromJson(role)
      );

      const mainRole =
        roles.length === 0
          ? null
          : roles.length > 1
          ? roles.filter((r) => r["ref"] !== UserRoleName.AGENT.description)[0]
          : roles[0];

      const lastName = json["last_name"];
      const firstName = json["first_name"];
      const name =
        lastName && firstName ? lastName.toUpperCase() + " " + firstName : "";
      const gender = json["gender"];
      const genderPronoun = gender === "F" ? "Mme" : "Mr";

      const obstructionDailyWaitingDepositsReceipts = (
        json["waiting_for_deposit_daily_taxes"] ?? []
      ).map((receipt) => DailyObstructionDepositReceipt.fromJson(receipt));

      const parkingWaitingDepositsReceipts = (
        json["waiting_for_deposit_parking_taxes"] ?? []
      ).map((receipt) => ParkingDepositReceipt.fromJson(receipt));

      const marketDailyWaitingDepositsReceipts = (
        json["waiting_for_deposit_market_daily_taxes"] ?? []
      ).map((receipt) => MarketDailyDepositTaxReceipt.fromJson(receipt));

      const marketMonthlyWaitingDepositsReceipts = (
        json["waiting_for_deposit_market_monthly_taxes"] ?? []
      ).map((receipt) => MarketMonthlyDepositReceipt.fromJson(receipt));

      const obstructionMonthlyWaitingDepositsReceipts = (
        json["waiting_for_deposit_monthly_taxes"] ?? []
      ).map((receipt) => MonthlyObstructionDepositReceipt.fromJson(receipt));

      const penaltiesWaitingDepositsReceipts = (
        json["waiting_for_deposit_penalty_taxes"] ?? []
      ).map((receipt) => PenaltyDepositReceipt.fromJson(receipt));

      const marketSimplePenaltyTaxesDepositsReceipts = (
        json["waiting_for_deposit_market_simple_penalty_taxes"] ?? []
      ).map((receipt) => MarketSimplePenaltyTaxReceipt.fromJson(receipt));

      return new User({
        ...json,
        agentId: json["agent_id"],
        name: name,
        nameComplete: genderPronoun + " " + name,
        gender: gender,
        genderLabel: gender === "F" ? "Féminin" : "Masculin",
        firstName: firstName,
        lastName: lastName,
        lastNameComplete: lastName
          ? genderPronoun + " " + lastName?.toUpperCase()
          : "",
        birthDate: json["birth_date"],
        email: json["email"],
        phone: json["phone"],
        searchSlug: json["search_slug"],
        roles: roles,
        mainRole: mainRole,
        mainRoleName: mainRole?.["name"],
        effectivesRolesName: roles.map((r) => r?.["name"]).join(", ") ?? "_",
        roleNames: json["role_names"],
        roleNamesJoined: (json["role_names"] ?? [])
          .map((role) => role.toUpperCase())
          .join(","),
        parkingWaitingDepositsReceipts: parkingWaitingDepositsReceipts,
        penaltiesWaitingDepositsReceipts: penaltiesWaitingDepositsReceipts,
        obstructionDailyWaitingDepositsReceipts:
          obstructionDailyWaitingDepositsReceipts,
        obstructionMonthlyWaitingDepositsReceipts:
          obstructionMonthlyWaitingDepositsReceipts,
        marketDailyWaitingDepositsReceipts: marketDailyWaitingDepositsReceipts,
        marketSimplePenaltyTaxesDepositsReceipts:
          marketSimplePenaltyTaxesDepositsReceipts,
        marketMonthlyWaitingDepositsReceipts:
          marketMonthlyWaitingDepositsReceipts,
        waitingDepositReceipts: [
          ...parkingWaitingDepositsReceipts,
          ...penaltiesWaitingDepositsReceipts,
          ...obstructionDailyWaitingDepositsReceipts,
          ...obstructionMonthlyWaitingDepositsReceipts,
          ...marketDailyWaitingDepositsReceipts,
          ...marketSimplePenaltyTaxesDepositsReceipts,
          ...marketMonthlyWaitingDepositsReceipts,
        ],
        paymentModes: (json["payment_modes"] ?? []).map((pm) =>
          PaymentMode.fromJson(pm)
        ),
        taxes: (json["taxes"] ?? []).map((tax) => Tax.fromJson(tax)),
        active: !json["disabled_at"],
        isActive: !json["disabled_at"],
      });
    } catch (e) {
      console.error(e);
    }
  }

  hasWaitingDeposits() {
    return (
      this.parkingWaitingDepositsReceipts.length > 0 ||
      this.penaltiesWaitingDepositsReceipts.length > 0 ||
      this.obstructionDailyWaitingDepositsReceipts.length > 0 ||
      this.obstructionMonthlyWaitingDepositsReceipts.length > 0 ||
      this.marketDailyWaitingDepositsReceipts.length > 0 ||
      this.marketSimplePenaltyTaxesDepositsReceipts.length > 0 ||
      this.marketMonthlyWaitingDepositsReceipts.length > 0
    );
  }

  withClearedWaitingDeposits() {
    this.parkingWaitingDepositsReceipts = [];
    this.penaltiesWaitingDepositsReceipts = [];
    this.obstructionDailyWaitingDepositsReceipts = [];
    this.obstructionMonthlyWaitingDepositsReceipts = [];
    this.marketDailyWaitingDepositsReceipts = [];
    this.marketSimplePenaltyTaxesDepositsReceipts = [];
    this.marketMonthlyWaitingDepositsReceipts = [];
    this.waitingDepositReceipts = [];
    return this;
  }

  waitingDepositsByCategory(category) {
    switch (category?.type?.description) {
      case "parking":
        return this.parkingWaitingDepositsReceipts;
      case "penalty":
        return this.penaltiesWaitingDepositsReceipts;
      case "obstruction":
        return category?.daily
          ? this.obstructionDailyWaitingDepositsReceipts
          : this.obstructionMonthlyWaitingDepositsReceipts;
      case "market":
        return category?.daily
          ? this.marketDailyWaitingDepositsReceipts
          : category?.monthly
          ? this.marketMonthlyWaitingDepositsReceipts
          : this.marketSimplePenaltyTaxesDepositsReceipts;
      default:
        return [];
    }
  }
}

export default User;
