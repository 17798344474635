export function withTimesTamps(json, field = "payment_date") {
  try {
    const paymentDate = json?.[field]?.substring(0, 10);
    const paymentTime = json?.[field]?.substring(11, 19);

    return {
      paymentDate: paymentDate,
      paymentTime: paymentTime,
      paymentDateTime: paymentDate + " " + paymentTime,
    };
  } catch (e) {
    return {};
  }
}

export function withStatus(json) {
  try {
    const canceledAt = json?.["canceled_at"];
    const deposedAt = json?.["agent_deposit_date"];

    return {
      status: !!canceledAt ? "Annulé" : !!deposedAt ? "Déposé" : "Collecté",
    };
  } catch (e) {
    return {};
  }
}
