import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from "./redux/store";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'moment/locale/fr';
import { LocalizationProvider } from "@mui/x-date-pickers";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const store = configureStore();

function App() {
  /*
  console.log(process.env.REACT_APP_VERSION);
  console.log(process.env.NODE_ENV);
  const isProduction = process.env.NODE_ENV !== "development";
  console.log(isProduction);
  */

  return (
    <LocalizationProvider adapterLocale={"fr"} dateAdapter={AdapterMoment}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter history={history}>
            <AppProvider>
              <JumboApp activeLayout={config.activeLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <JumboDialogProvider>
                      <JumboDialog />
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        maxSnack={3}
                      >
                        <AppLayout>
                          <Suspense
                            fallback={
                              <Div
                                sx={{
                                  display: "flex",
                                  minWidth: 0,
                                  alignItems: "center",
                                  alignContent: "center",
                                  height: "100%",
                                }}
                              >
                                <CircularProgress sx={{ m: "-40px auto 0" }} />
                              </Div>
                            }
                          >
                            <AppRoutes />
                          </Suspense>
                        </AppLayout>
                      </SnackbarProvider>
                    </JumboDialogProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
            </AppProvider>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
