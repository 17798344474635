class PaymentMode {
  constructor(payment) {
    Object.assign(this, payment);
  }

  static fromJson(receipt) {
    return new PaymentMode({
      ...receipt,
      label: receipt["label"],
      code: receipt["code"],
      requiredId: receipt["required_id"],
    });
  }
}

export default PaymentMode;
