import React, { Suspense } from "react";
import { IconButton, Tooltip } from "@mui/material";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboLayoutSidebar, useJumboSidebarTheme } from "@jumbo/hooks";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "app/shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { authUserSelector, onAuthLogout } from "app/pages/auth/hooks/authSlice";
import { useDispatch, useSelector } from "react-redux";
import SpaceBetweenDiv from "cofa/components/grid/SpaceBetweenedDiv";
import CustomListItemText from "cofa/components/text/CustomListItemText";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LogoutIcon from "@mui/icons-material/Logout";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useDynamicMenus } from "app/routes/useDynamicMenus";

const Sidebar = () => {
  const { showDialog, hideDialog } = useJumboDialog();

  const onLogout = () => {
    showDialog({
      variant: "confirm",
      title: "Se déconnecter de cet appareil ?",
      content: "",
      onYes: () => {
        dispatch(onAuthLogout());
        hideDialog();
      },
      onNo: hideDialog,
      confirmButtonText: "Se déconnecter",
      cancelButtonText: "Non",
    });
  };

  const user = useSelector(authUserSelector);

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={useDynamicMenus()} />
        </Suspense>
      </JumboScrollbar>
      <SpaceBetweenDiv mb={1} alignItems={"baseline"}>
        <CustomListItemText
          sx={{ p: 0 }}
          primary={user?.mainRole?.name}
          secondary={user?.name}
          icon={<AssignmentIndIcon fontSize={"large"} />}
        />
        <Tooltip title={"Se déconnecter"}>
          <IconButton onClick={onLogout} aria-label="Se déconnecter">
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </SpaceBetweenDiv>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          }
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
