import React from "react";
import { homeMenus } from "app/routes/homeMenus";
import { useAuth } from "app/routes/hooks/useAuth";
import { usersMenus } from "app/routes/usersMenus";
import { cashierMenus } from "app/routes/cashierMenus";
import { agentsReceiptsAndManagement } from "app/routes/agentsReceiptsAndManagement";
import { taxPayersMenu } from "app/routes/taxPayersMenu";
import { settingsMenus } from "app/routes/settingsMenu";
import { appDataMenu } from "app/routes/appDataMenu";
import { receiptsCancellationsMenu } from "app/routes/receiptsMenus";

export function useDynamicMenus() {
  const { isCashier, isSupport, isAgent, isController, isDAF, isConsultative } =
    useAuth();

  return isConsultative || isController
    ? [
        ...homeMenus,
        ...taxPayersMenu,
        ...agentsReceiptsAndManagement,
        ...cashierMenus,
        ...settingsMenus,
      ]
    : [
        ...homeMenus,
        ...(isAgent ? taxPayersMenu : []),
        ...(isSupport
          ? [
              ...usersMenus,
              ...taxPayersMenu,
              ...agentsReceiptsAndManagement,
              ...cashierMenus,
              ...appDataMenu,
              ...receiptsCancellationsMenu,
            ]
          : []),
        ...(isDAF
          ? [
              ...usersMenus,
              ...taxPayersMenu,
              ...appDataMenu,
              ...agentsReceiptsAndManagement,
              ...cashierMenus,
              ...receiptsCancellationsMenu,
            ]
          : []),
        ...(isCashier ? [...agentsReceiptsAndManagement, ...cashierMenus] : []),
        ...settingsMenus,
      ];
}
