import React from "react";

const ActivitiesPage = React.lazy(() => import("app/pages/activities"));
const InvoicesTypesPage = React.lazy(() => import("app/pages/invoiceTypes"));
const PenaltyTypesPage = React.lazy(() => import("app/pages/penaltyTypes"));
const ParkingTypesPage = React.lazy(() => import("app/pages/parkingTypes"));
const ZonesPage = React.lazy(() => import("app/pages/zones"));
const MarketsPage = React.lazy(() => import("app/pages/markets"));
export const appData = [
  {
    path: "/app/activities",
    element: <ActivitiesPage />,
  },
  {
    path: "/app/invoice-types",
    element: <InvoicesTypesPage />,
  },
  {
    path: "/app/penalty-types",
    element: <PenaltyTypesPage />,
  },
  {
    path: "/app/parking-types",
    element: <ParkingTypesPage />,
  },
  {
    path: "/app/markets",
    element: <MarketsPage />,
  },
  {
    path: "/app/zones",
    element: <ZonesPage />,
  },
];
