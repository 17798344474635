export const yellowColor = "#807d00";
export const yellowLightColor = "#fffa00";
//export const yellowLightColor = "#f0dc90";
export const yellowDarkColor = "#333200";

export const yellowBrownColor = "#fffa00";
export const yellowBrownLightColor = "#999600";
export const yellowBrownDarkColor = "#fffc66";

export const greenColor = "#00873f";
export const greenLightColor = "#4dab79";
export const greenDarkColor = "#003619";

export const dividerColor = "#e9cd60";

export const redColor = "#f40303";
export const redDarkColor = "#620101";
export const redLightColor = "#f86868";

export const mainColor = yellowColor;
