import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducers from "app/pages/auth/hooks/authSlice.js";
import usersReducers from "app/pages/users/hooks/usersSlice.js";
import agentsReducers from "app/pages/agents/hooks/agentsSlice.js";

const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    users: usersReducers,
    agents: agentsReducers,
  });
};

export default exportReducers;
