class Tax {
  constructor(receipt) {
    Object.assign(this, receipt);
  }

  static fromJson(receipt) {
    return new Tax({
      ...receipt,
      code: receipt["code"],
      label: receipt["label"],
      type: receipt["type"],
      periodicity: receipt["periodicity"],
      amount: receipt["amount"],
      openDocument: receipt["open_document"],
      openAt: receipt["open_at"],
    });
  }
}

export default Tax;
