import React from "react";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const receiptsCancellationsMenu = [
  {
    label: "sidebar.menu.cancellations",
    type: "section",
    uri: "/receipts/cancellations",
    icon: <RemoveDoneIcon sx={{ fontSize: 20, color: "red" }} />,
    children: [
      {
        uri: "/receipts/cancellations/new",
        label: "sidebar.menuItem.newCancellation",
        type: "nav-item",
        icon: <FiberNewIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/receipts/cancellations/list",
        label: "sidebar.menuItem.listCancellations",
        type: "nav-item",
        icon: <FormatListBulletedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];
