// noinspection JSIgnoredPromiseFromCall

import { createSlice } from "@reduxjs/toolkit";
import axios from "app/services/config";
import { isFunction } from "cofa/utils";
import User from "app/pages/users/models/User";
import customHttpError from "cofa/utils/exceptions/custom_error";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    error: null,
    status: "idle", //"pending", "failed", "succeeded",
    agentId: null,
    otpCode: null,
  },
  reducers: {
    authStatus(state, action) {
      state.status = action.payload;
    },
    setAgentId(state, action) {
      state.agentId = action.payload;
    },
    setOtpCode(state, action) {
      state.otpCode = action.payload;
    },
    userLoggedIn(state, action) {
      state.user = User.fromJson(action.payload);
    },
    userLogOut(state) {
      state.user = null;
    },
    userLoginFail(state, action) {
      state.error = action.payload;
    },
    setAuthIsLoading(state, action) {
      state.loading = action.payload;
      if (state.loading) state.error = null;
    },
  },
});

export const {
  authStatus,
  userLoggedIn,
  userLoginFail,
  setAuthIsLoading,
  userLogOut,
  setAgentId,
  setOtpCode,
} = authSlice.actions;

export const authError = (state) => state.auth.error;
export const authIsLoading = (state) => state.auth.loading;
export const authStatusSelector = (state) => state.auth.status;
export const authStatusIsLoadingSelector = (state) =>
  state.auth.status === "idle" || state.auth.status === "pending";
export const authUserSelector = (state) => state.auth.user;
export const authAgentIdSelector = (state) => state.auth.agentId;

export const authOtpCodeSelector = (state) => state.auth.otpCode;

export const loginWithEmail = (email, password, { onSuccess, onFailure }) => {
  return (dispatch) => {
    dispatch(setAuthIsLoading(true));

    axios
      .post(
        "/login",
        { email: email, password: password },
        {
          validateStatus: function (_) {
            return true;
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          let type = response.data.data.type;
          let token = response.data.data.token;
          localStorage.setItem("token_type", type);
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = type + " " + token;
          dispatch(
            getAuthUser({ token, tokenType: type, onSuccess, onFailure })
          );
        } else {
          let message = response.data.message;
          dispatch(userLoginFail(message));
          dispatch(setAuthIsLoading(false));
          if (isFunction(onFailure))
            onFailure("Identifiants de connexion invalides.");
        }
      })
      .catch((e) => {
        let message = "Une erreur s'est produite. \nRéessayez plus tard.";
        dispatch(setAuthIsLoading(false));
        dispatch(userLoginFail(e?.message ?? message));
        if (isFunction(onFailure)) onFailure(message);
        console.error(e);
      });
  };
};

export const loginWithMatricule = ({ agentId, otp, onSuccess, onFailure }) => {
  return (dispatch) => {
    dispatch(setAuthIsLoading(true));

    axios
      .post(
        "/login",
        {
          agent_id: agentId,
          otp: otp,
        },
        {
          validateStatus: function (_) {
            return true;
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          dispatch(setAgentId(null));
          let token = response.data.data.token;
          let type = response.data.data.type;
          localStorage.setItem("token", token);
          localStorage.setItem("token_type", type);
          axios.defaults.headers.common["Authorization"] = type + " " + token;
          dispatch(
            getAuthUser({ token, tokenType: type, onSuccess, onFailure })
          );
        } else {
          let message = response.data.message;
          dispatch(userLoginFail(message));
          dispatch(setAuthIsLoading(false));
          if (isFunction(onFailure))
            onFailure("Identifiants de connexion invalides.");
        }
      })
      .catch((e) => {
        let message = "Une erreur s'est produite. \nRéessayez plus tard.";
        dispatch(setAuthIsLoading(false));
        dispatch(userLoginFail(e?.message ?? message));
        if (isFunction(onFailure)) onFailure(message);
        console.error(e);
      });
  };
};

export const _getOtpCodeDev = (data) => {
  try {
    const info = (data ?? '||').split('|');
    const isDev = (info?.[2] ?? "").length <= 6;
    return {
      isDev: isDev,
      phone: isDev ? info[0] : info[2],
      code: isDev ? info[2] : undefined,
    };
  } catch (_) {
    return undefined;
  }
};

export const sendOTP = (agentId, { onSuccess, onFailure }) => {
  return (dispatch) => {
    axios
      .get("sendOtp/" + agentId, {
        validateStatus: function (_) {
          return true;
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          dispatch(setAgentId(agentId));
          dispatch(userLoginFail(null));
          dispatch(setOtpCode(_getOtpCodeDev(response.data.data)));
          if (isFunction(onSuccess)) onSuccess(response.data.data);
        } else {
          let message =
            response.data.message ?? "Identifiant de connexion invalide.";
          if (response.status === 404) {
            message = "Cet utilisateur n'existe pas.";
          } else {
          }

          dispatch(setAgentId(null));
          if (isFunction(onFailure)) onFailure(message);
        }
      })
      .catch((e) => {
        if (isFunction(onSuccess)) onFailure(customHttpError(e).message);
      });
  };
};

export const getAuthUser = ({
  token,
  tokenType = "Bearer",
  onSuccess,
  onFailure,
}) => {
  return (dispatch) => {
    dispatch(authStatus("pending"));

    token = token ?? localStorage.getItem("token");
    tokenType = tokenType ?? localStorage.getItem("token_type");

    axios
      .get("/user", {
        headers: {
          Authorization: tokenType + " " + token,
        },
      })
      .then(({ data }) => {
        if (data.success === true) {
          dispatch(userLoggedIn(User.fromJson(data.data)));
          dispatch(authStatus("succeeded"));
          axios.defaults.headers.common["Authorization"] =
            tokenType + " " + token;
          if (isFunction(onSuccess)) onSuccess();
        } else {
          dispatch(authStatus("failed"));
          if (isFunction(onFailure)) onFailure();
        }
        dispatch(setAuthIsLoading(false));
      })
      .catch(function (_) {
        dispatch(authStatus("failed"));
        dispatch(setAuthIsLoading(false));
      });
  };
};

export const onAuthLogout = () => {
  return (dispatch) => {
    dispatch(setAuthIsLoading(true));

    axios
      .post("/logout")
      .then(({ data }) => {
        if (data.success === true) {
        }
        dispatch(setAuthIsLoading(false));
      })
      .catch(function (_) {
        dispatch(setAuthIsLoading(false));
      })
      .finally(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_type");
        axios.defaults.headers.common["Authorisation"] = "";
        dispatch(userLogOut());
      });
  };
};

export default authSlice.reducer;
