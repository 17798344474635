export const AgentCollectorRole = Object.freeze({
  MARKET: Symbol("market"),
  PARKING: Symbol("parking"),
  OBSTRUCTION: Symbol("obstruction"),
  NOT_COLLECTOR: Symbol("not_collector"),
});

export const placeTypeNameOf = (collectorRole) => {
  switch (collectorRole) {
    case AgentCollectorRole.MARKET:
      return "Marché";

    case AgentCollectorRole.PARKING:
      return "Stationnement";

    case AgentCollectorRole.OBSTRUCTION:
      return "Encombrement";

    case AgentCollectorRole.NOT_COLLECTOR:
    default:
      return null;
  }
};

export function getPlaceNameFromUserRole(collectorRole, userRole) {
  try {
    switch (collectorRole) {
      case AgentCollectorRole.MARKET:
        return userRole["market_name"];

      case AgentCollectorRole.PARKING:
      case AgentCollectorRole.OBSTRUCTION:
        return userRole["zone_name"];

      case AgentCollectorRole.NOT_COLLECTOR:
      default:
        return "";
    }
  } catch (e) {
    console.error(e);
  }
}
