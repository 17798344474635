import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

const noOverflowCss = {
  textOverflow: "ellipsis",
  overflow: "hidden",
};

const CustomListItemText = ({
  icon,
  primary = "",
  secondary = "",
  iconWidth = 36,
  iconColor = "text.primary",
  sx = {},
}) => {
  return (
    <ListItem sx={{ p: (theme) => theme.spacing(1.5, 3), ...sx }}>
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: iconWidth,
            color: iconColor,
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          sx: { ...noOverflowCss, color: "text.secondary" },
        }}
        secondaryTypographyProps={{
          sx: { ...noOverflowCss, color: "text.primary" },
          fontSize: "1rem",
        }}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};

export default CustomListItemText;
