import React from 'react';
import Avatar from '@mui/material/Avatar';
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import {useJumboTheme} from '@jumbo/hooks';
import {useDispatch} from 'react-redux';
import {onAuthLogout} from 'app/pages/auth/hooks/authSlice';
import {useNavigate} from 'react-router-dom';
import {useJumboDialog} from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import {useAuth} from 'app/routes/hooks/useAuth';

const AuthUserDropdown = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {user} = useAuth();
	const {theme} = useJumboTheme();
	const {showDialog, hideDialog} = useJumboDialog();

	const onLogout = () => {
		showDialog({
			variant: 'confirm',
			title: 'Se déconnecter de cet appareil ?',
			content: '',
			onYes: () => {
				dispatch(onAuthLogout());
				hideDialog();
			},
			onNo: hideDialog,
			confirmButtonText: 'Se déconnecter',
			cancelButtonText: 'Non',
		});
	};

	return (
		<ThemeProvider theme={theme}>
			<JumboDdPopover
				triggerButton={
					<Avatar sizes={'small'} sx={{boxShadow: 25, cursor: 'pointer'}} />
				}
			>
				{user && (
					<Div
						sx={{
							width: 'auto',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							p: (theme) => theme.spacing(2.5),
						}}
					>
						<Typography variant={'h5'}>{user.name}</Typography>
						<Typography variant={'h5'}>{user.agentId}</Typography>
						<Typography variant={'h5'}>{user.email}</Typography>
						{/*<Typography variant={"body1"} color="text.secondary">
              {user.email}
            </Typography>*/}
						{user?.mainRole && (
							<Typography variant={'body1'} color='text.secondary'>
								{user?.mainRole?.description}
							</Typography>
						)}
					</Div>
				)}
				<Divider />
				<nav>
					<List disablePadding sx={{pb: 1}}>
						<ListItemButton onClick={() => navigate('/profile')}>
							<ListItemIcon sx={{minWidth: 36}}>
								<PersonOutlineIcon />
							</ListItemIcon>
							<ListItemText primary='Profil' sx={{my: 0}} />
						</ListItemButton>
						{/*
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Modifier le Profil" sx={{ my: 0 }} />
              </ListItemButton>
            */}
						{/*<ListItemButton>
              <ListItemIcon sx={{minWidth: 36}}>
                <RepeatOutlinedIcon/>
              </ListItemIcon>
              <ListItemText
                onClick={() => navigate("/samples/content-layout")}
                primary="Switch User"
                sx={{my: 0}}/>
            </ListItemButton>*/}
						<ListItemButton onClick={onLogout}>
							<ListItemIcon sx={{minWidth: 36}}>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText primary='Se déconnecter' sx={{my: 0}} />
						</ListItemButton>
					</List>
				</nav>
			</JumboDdPopover>
		</ThemeProvider>
	);
};

export default AuthUserDropdown;
