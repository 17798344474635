import { ToWords } from "to-words";

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Find the alpha prefix in the string s.
 * Exemple : console.log(findAlphaPrefix("AB1234")); //AB
 *
 * Return undefined if s is empty or non defined (undefined|null).
 * @param s
 * @returns {undefined|*}
 */
export function findAlphaPrefix(s) {
  try {
    return s.slice(0, s.search(/\d/));
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export const capitalizeOnlyFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeAllWords = (string) => {
  return string.split(" ").map(capitalize).join(" ");
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

const SEPARATOR_CURRENCY = " ";

export const formatCurrency = (
  amount,
  { blockLength = 3, separator = SEPARATOR_CURRENCY } = {
    blockLength: 3,
    separator: SEPARATOR_CURRENCY,
  }
) => {
  let noSeparatorAmount = ((amount ?? "") + "").replaceAll(separator, "") ?? "";

  if (noSeparatorAmount.length >= blockLength) {
    let temp = "";
    let k = 0;
    let modulo = noSeparatorAmount.length % blockLength;
    let start = modulo === 0 ? blockLength : modulo;
    for (let i = start; i < noSeparatorAmount.length; i += blockLength) {
      temp += noSeparatorAmount.substring(i - blockLength, i) + separator;
      k = i;
    }
    noSeparatorAmount =
      temp + noSeparatorAmount.substring(k, noSeparatorAmount.length);
  }

  return noSeparatorAmount.trim();
};

export const handleInputChange = ({
  event,
  setFieldValue,
  capitalizeFirst = true,
  capitalizeWords = false,
  upperCase = false,
  decimal = false,
}) => {
  const input = event.target;
  const start = input.selectionStart;
  const end = input.selectionEnd;

  if (decimal) {
    setFieldValue(isNumeric(input.value) ? input.value : null);
  } else {
    setFieldValue(
      upperCase
        ? input.value.toUpperCase()
        : capitalizeWords
        ? capitalizeAllWords(input.value)
        : capitalizeFirst
        ? capitalize(input.value)
        : input.value
    );
  }

  input.setSelectionRange(start, end);
};

const toWordsBasicOptions = {
  localeCode: "fr-FR",
  converterOptions: {
    currency: false,
    ignoreDecimal: true,
    ignoreZeroCurrency: true,
    doNotAddOnly: true,
  },
};

const toWords = new ToWords({
  ...toWordsBasicOptions,
  converterOptions: {
    ...toWordsBasicOptions.converterOptions,
    currency: true,
    currencyOptions: {
      name: "FCFA",
      plural: "FCFA",
      symbol: "F.CFA",
      /*fractionalUnit: {
        name: 'Centime',
        plural: 'Centimes',
        symbol: '',
      },*/
    },
  },
});

const toWordsWithoutCurrency = new ToWords(toWordsBasicOptions);

export const currencyToWords = (amount, withCurrency = true) => {
  try {
    return capitalizeOnlyFirst(
      (withCurrency ? toWords : toWordsWithoutCurrency).convert(amount)
    );
  } catch (e) {
    return "";
  }
};

const include = (value = "", s) => {
  try {
    return (value + "")?.toLowerCase().includes(s.toLowerCase()) ?? false;
  } catch (e) {
    return false;
  }
};

export const textIncludes = (value = "", s) => {
  try {
    return (value + "")?.toLowerCase().includes(s.toLowerCase()) ?? false;
  } catch (e) {
    return false;
  }
};

export const searchInFields = (search, searchIns, items) => {
  return search === ""
    ? items
    : items.filter((item) => {
        for (const field of searchIns) {
          if (
            textIncludes(item[field], search) ||
            item[field]?.toLowerCase() === search.toLowerCase()
          )
            return true;
        }
        return false;
      });
};
