import React from "react";
const AgentsManagementPage = React.lazy(() => import("app/pages/agents"));
const AgentsReceiptsManagementPage = React.lazy(() =>
  import("app/pages/receipts/pages")
);
const CashierReportsPage = React.lazy(() =>
  import("app/pages/receipts/pages/Reports")
);
const AgentDetailPage = React.lazy(() =>
  import("app/pages/agents/components/AgentPageDetail")
);
const ReceiptsListByCategoryPage = React.lazy(() =>
  import("app/pages/agents/components/ReceiptsListByCategoryPage")
);
const AgentsDepositsPage = React.lazy(() =>
  import("app/pages/receipts/pages/Deposits")
);
const DepositsHistoryListPage = React.lazy(() =>
  import("app/pages/deposits")
);
export const cashierPaths = [
  {
    path: "/agents/management",
    element: <AgentsManagementPage />,
  },
  /*{
    path: "/agents/deposits",
    element: <AgentsDepositsPage />,
  },*/
  {
    path: "/agents/deposits",
    element: <DepositsHistoryListPage />,
  },
  {
    path: "/agents/reports",
    element: <CashierReportsPage />,
  },
  {
    path: "/agents/receipts",
    element: <AgentsReceiptsManagementPage />,
  },
  {
    path: "/agents/detail",
    element: <AgentDetailPage />,
  },
  {
    path: "/agents/detail/waiting-deposits",
    element: <ReceiptsListByCategoryPage />,
  },
];
