import React from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "app/routes/hooks/useAuth";

const NotAuth = ({ fallbackPath }) => {
  const { authUser } = useAuth();

  if (authUser?.isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!authUser.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    if (authUser?.data === null || authUser?.data === undefined) {
      return <Outlet />;
    } else {
      return <Navigate to={fallbackPath} />;
    }
  }
};

export default NotAuth;
