import React from "react";
const TaxPayersList = React.lazy(() => import("app/pages/taxPayers"));
const UnPaidTaxPayersList = React.lazy(() => import("app/pages/taxPayers/pages/unPaid"));
export const agentPaths = [
  {
    path: "/tax-payers/all",
    element: <TaxPayersList />,
  },
  {
    path: "/tax-payers/unpaid",
    element: <UnPaidTaxPayersList />,
  },
];
