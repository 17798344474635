import React from "react";

const LoginPage = React.lazy(() => import("app/pages/auth/components"));
const OTPPage = React.lazy(() => import("app/pages/auth/components/otp"));

export const authRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/otp",
    element: <OTPPage />,
  },
];
